.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  /*min-height: 10vh;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.logoText {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
}

.App-link {
  color: #61dafb;
}

.float_container {
}

.float_child {
    float: left;
}

.fright {
    float: right;
}

div#left_content_area {
    padding: 10px;
    min-width: 300px;
    text-align: left;
    background-color: #eee;
    font-size: 1.4em;
}

div#right_content_area {
    padding: 20px;
    min-width: 650px;
    text-align: left;
}

.content_area {
    padding: 0px;
}

.fs14 { font-size: 1.4em; }
.fs12 { font-size: 1.2em; }
.fs10 { font-size: 1.0em; }
.fs08 { font-size: 0.8em; }
.fs06 { font-size: 0.6em; }

.mt10 { margin-top: 10px; }
.mt20 { margin-top: 20px; }

.ml10 { margin-left: 10px; }
.ml20 { margin-left: 20px; }

.padding5 { padding: 5px; }
.padding10 { padding: 10px; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
